<div class="custom-header">
  <div class="left">
    <div class="mod-logo">
      <div class="logo-wrapper" (click)="gotoStartPage()"></div>
      <ng-container *featureFlag="'displayEnvironment'">
        <div class="environment">
          <mat-icon>{{ currentEnvironment.icon }}</mat-icon>
          <p class="bold">{{ currentEnvironment.type }}</p>
        </div>
      </ng-container>
    </div>
  </div>
  <coin-user-menu [isDeputyAllowed]="false" [isProfileAllowed]="false"></coin-user-menu>
  <div class="right">
    <!-- <div class="header-icons">
      <coin-action-button [disabled]="true" [noicon]="true" [iconTextDistance]="0" [iconTextMargin]="0"><p class="bold" style="font-size: 18px;">?</p></coin-action-button>
      <coin-action-button [disabled]="true" icon="search" [iconTextDistance]="0" [iconTextMargin]="0" btnPadding="0.55em"></coin-action-button>
    </div> -->
  </div>
</div>
