import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@coin/shared/util-environments';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  get currentEnvironment(): typeof environment {
    return environment;
  }

  constructor(private router: Router) {}

  public gotoStartPage(): void {
    this.router.navigate(['/']);
  }
}
